// Libs
import React from 'react';
import moment from 'moment';
import * as PropTypes from 'prop-types';
import StoreHours from '../common/store/store-hours';
import { get, has, isEmpty, sortBy } from 'lodash';
// Deps
import StoreLocationMap from '../common/store/store-location-map';
import StoreFlyerLink from './store-flyer-link';
import StoreSetAction from './store-set-action';
import StoreAmenities from './store-amenities';
// Custom Libs.
import { storeDrupalId } from '../../libs/store/store-utils';
// Hooks.
import useGoogleMapsLoader from '../../hooks/useGoogleMapsLoader';
// Assets
import styles from './store-details.module.scss';
import StoreOpenNotice from './store-open-notice';
import PagePreloader from '../common/loaders/page-preloader';
import StoreWhatsIn from './store-whats-in';
import StoreHolidayHours from '../common/store/store-holiday-hours';
import StorePromotedFeature from './store-promoted-feature';

const StoreDetails = ({ store, variation='desktop' }) => {
  const pharmacyPhone = get(store, 'field_store_pharmacy_phone', '');
  const pharmacyLink = pharmacyPhone ? `tel:${store.field_store_pharmacy_phone}` : '';
  const phoneLink = `tel:${store.field_store_phone}`;
  const googleMapsLoad = useGoogleMapsLoader();

  let holidays = [];
  // TODO: Look in to support dates with SSR builds...
  if (has(store, 'relationships.field_holidays')) {
    holidays = store.relationships.field_holidays;
    if (!isEmpty(holidays)) {
      holidays = holidays.filter((holiday) =>  moment(holiday.date, 'YYYY-MM-DD').isSameOrAfter(moment(), 'day') );
    }
  }

  const address =
    (store &&
      store.address &&
      `${store.field_store_address.address_line1} ${store.field_store_address.locality} ${store.field_store_address.postal_code}`) ||
    (store && store.address1 && `${store.address1} ${store.city} ${store.postalCode}`) ||
    '';
  return (
    <div className={styles.storeDetails}>
      <div className={styles.storeDetailsContent}>
        <h1 className={styles.storeDetailsName}>{store.title}</h1>
        <div className={styles.storeDetailsActions}>
          <StoreSetAction storeId={storeDrupalId(store)}/>
          <StoreFlyerLink link={store.field_store_flyer_link}/>
        </div>
        <div className={styles.storeDetailsColumns}>
          <div className={styles.storeDetailsInfoColumn}>
            <div className={styles.storeDetailsOpenUntil}>
              <StoreOpenNotice store={store}/>
            </div>
            <div className={styles.storeDetailsAddress}>
              {address}
              {!!address && (
                <a
                  href={`https://www.google.com/maps/dir/?api=1&destination=${address}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Directions
                </a>
              )}
            </div>
            <div className={styles.storeDetailsPhoneNumbers}>
              <div>
                Store: <a href={phoneLink}>{store.field_store_phone}</a>
              </div>
              {!!pharmacyPhone && !!pharmacyLink && <div>
                Pharmacy: <a href={pharmacyLink}>{pharmacyPhone}</a>
              </div>}
            </div>
            <br />
            {!isEmpty(store.textBox)} <div className={styles.storeDetailsTextbox}>
              {store.textBox}
            </div>
            {!isEmpty(store.storeHours) && (<div className={styles.storeDetailsHours}>
              <h3>Store hours</h3>
              <StoreHours storeHours={sortBy(store.storeHours, ['day'])}/>
          </div>)}
            {!isEmpty(holidays) && (
              <div className={styles.storeDetailsHolidayHours}>
                <h3>Holiday Hours</h3>
                <StoreHolidayHours store={store} />
              </div>
            )}
            {!isEmpty(store.field_store_pharmacy_hours) &&
            <div className={styles.storeDetailsPharmacyHours}>
              <h3>Pharmacy Hours</h3>
              <StoreHours storeHours={sortBy(store.field_store_pharmacy_hours, ['day'])}/>
            </div>
            }
          </div>
          <div className={styles.storeDetailsMapColumn}>
            {googleMapsLoad ? (
              <StoreLocationMap
                google={window.google}
                focusPoint={{
                  lat: store.latitude || '43.8271162',
                  lng: store.longitude || '-79.5590672',
                }}
                zoom={14}
              />
            ) : (
              <PagePreloader/>
            )}
          </div>
          <StoreAmenities
            amenities={{
              bathrooms: store.field_store_accessible_bathrooms,
              entrance: store.field_store_accessible_entrance,
              parking: store.field_store_parking,
            }}
          />
        </div>
        {!isEmpty(store.relationships.field_store_feature_department) ? (
          <StorePromotedFeature
            department={store.relationships.field_store_feature_department}
          />
        ) : (
          ''
        )}
          {!isEmpty(store.relationships.field_store_services) &&
        !isEmpty(store.relationships.field_store_food_and_drink) ? (
          <StoreWhatsIn store={store} />
        ) : (
          ''
        )}
      </div>
    </div>
  );
};

StoreDetails.propTypes = {
  store: PropTypes.object.isRequired,
};

export default StoreDetails;
