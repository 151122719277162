// Libs
import React from 'react';
// Assets
import styles from './store-hours.module.scss';
import { groupBy, map, sortBy } from 'lodash';
import { transformTo12 } from '../../../libs/timeTransform';
import * as PropTypes from 'prop-types';
import { divmod } from '../../../libs/math';
import moment from 'moment';

const StoreHours = ({ storeHours, holidays = null }) => {
  if (!(Array.isArray(storeHours) && storeHours.length)) {
    return '';
  }
  const holidayDates = [];
  if (Array.isArray(holidays)) {
    holidays.forEach((holiday) => {
      if (moment(holiday.date).day() !== new Date().getDay()) {
        holidayDates.push(moment(holiday.date).day());
      }
    });
  }
  const daysName = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];
  let result = [...storeHours];
  result[0].day = result[0].day === 0 ? 7 : result[0].day;
  result = sortBy(result, ['day']);
  let result1 = groupBy(result, (x) => {
    const [startHour, startMinutes] = divmod(x.starthours, 100);
    const [endHour, endMinutes] = divmod(x.endhours, 100);
    /*return x.starthours !== x.endhours && holidayDates.indexOf(x.day) < 0
      ? `${transformTo12(startHour, startMinutes)}-${transformTo12(endHour, endMinutes)}`
      : `Closed`;*/
    return `${transformTo12(startHour, startMinutes)}-${transformTo12(endHour, endMinutes)}`;
  });
  return Object.getOwnPropertyNames(result1).map((key) => {
    let item = sortBy(result1[key], ['day']);
    // if (item.length === 2) item = item.reverse();
    const first = item[0].day;
    const last = item[item.length - 1].day;
    if (first !== last) {
      return (
        <div key={key} className={styles.storeDetailsHoursColumn}>
          <span>
            {daysName[first]} &mdash; {daysName[last]}:
          </span>
          {key.indexOf('-') > -1 && (
            <span>
              {key.split('-')[0]} &mdash; {key.split('-')[1]}
            </span>
          )}
          {key.indexOf('-') === -1 && <span>{key}</span>}
        </div>
      );
    } else {
      return (
        <div key={key} className={styles.storeDetailsHoursColumn}>
          <span>{daysName[first]}:</span>
          {key.indexOf('-') > -1 && (
            <span>
              {key.split('-')[0]} &mdash; {key.split('-')[1]}
            </span>
          )}
          {key.indexOf('-') === -1 && <span>{key}</span>}
        </div>
      );
    }
  });
};

StoreHours.propTypes = {
  storeHours: PropTypes.array.isRequired,
};

export default StoreHours;
