import React from 'react';
import * as PropTypes from 'prop-types';
import styles from './store-amenities.module.scss';

import accessibleBathroomIcon from '../../images/icons/icon-wheelchair.svg';
import accessibleEntranceIcon from '../../images/icons/icon-accessible-entrance.svg';
import parkingIcon from '../../images/icons/icon-parking.svg';

const StoreAmenities = ({ amenities }) => {
  const defaultAmenities = {
    parking: { icon: 'parkingIcon', label: 'Parking', available: true },
    entrance: {
      icon: 'accessibleEntranceIcon',
      label: 'Accessible Entrance',
      available: true,
    },
    bathrooms: {
      icon: 'accessibleBathroomIcon',
      label: 'Accessible Bathrooms',
      available: true,
    },
  };
  return (
    <div className={styles.storeAmenities}>
      {Object.keys(amenities).map((key, index) => {
        const item = defaultAmenities[key];
        item.available = amenities[key];

        return (
          <div className={styles.storeAmenitiesItem} key={index}>
            <div>
              <img
                src={
                  item.icon === 'parkingIcon'
                    ? parkingIcon
                    : item.icon === 'accessibleEntranceIcon'
                    ? accessibleEntranceIcon
                    : accessibleBathroomIcon
                }
                alt=""
              />
            </div>
            <div>
              {item.label} ({item.available ? 'Y' : 'N'})
            </div>
          </div>
        );
      })}
    </div>
  );
};

StoreAmenities.propTypes = {
  amenities: PropTypes.object.isRequired,
};

export default StoreAmenities;
