import { envLevel } from './api';

// Load google Google Places API external library.
export function loadGoogleMapsLib() {
  const API_KEY = envLevel() === 'dev' || envLevel() === 'test' ?
    'AIzaSyCx53s1l5oLXq1T8JMA3hqqhAkwFMwweIg' : // Dev key
    'AIzaSyA1APXWF4xpngjAye5OmLXA6wfkGXZKQcQ'; // Production key

  return new Promise((resolve) => {
    if (!window.google) {
      window.resolveLoadGooglePlacesLib = () => {
        resolve(window.google);
        delete window.resolveLoadGooglePlacesLib;
      };

      const script = document.createElement('script');
      script.type = 'text/javascript';
      script.src = `https://maps.googleapis.com/maps/api/js?key=${API_KEY}&libraries=places&callback=resolveLoadGooglePlacesLib`;
      script.async = true;
      const scriptElement = document.getElementsByTagName('script')[0];
      scriptElement.parentNode.insertBefore(script, scriptElement);
    } else {
      resolve(window.google);
    }
  });
}
