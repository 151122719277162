// Libs
import React, {useState, useEffect, useContext} from 'react';
import * as PropTypes from 'prop-types';
// Deps
import { storeOpenHours } from '../../libs/storeOpenHours';
import {AppContext} from "../layout/AppLayout";

const StoreOpenNotice = ({ store }) => {
  const isApp = useContext(AppContext);
  let noticeInterval;
  const intervalTimeout = 5000; // 5s
  const [openNotice, setOpenNotice] = useState(storeOpenHours(store, isApp));

  // Set a interval to update the stores open/closed notice.
  useEffect(() => {
    noticeInterval = setInterval(() => {
      setOpenNotice(storeOpenHours(store, isApp));
    }, intervalTimeout);

    // Ensure to clear out the interval on deconstruct.
    return () => {
      clearInterval(noticeInterval);
    };
  }, []);

  return <>{openNotice}</>;
};

StoreOpenNotice.defaultProps = {
  store: null,
};

StoreOpenNotice.propTypes = {
  store: PropTypes.object.isRequired,
};

export default StoreOpenNotice;
