import React, {useContext} from 'react';
import moment from 'moment';
import * as PropTypes from 'prop-types';
import styles from './store-flyer-week.module.scss';
import Img from 'gatsby-image';
import {AppContext} from "../layout/AppLayout";


const StoreFlyerWeek = ({ store }) => {
  let flyerImage;
  // let flyerExpiration;
  // const { startDate, endDate } = store.flyerExpiration || {};
  // const flyerDate = (d) => moment(d, 'YYYY-MM-DD').format('ddd MMMM Do');
  const app = useContext(AppContext);


  try {
    flyerImage = (
      <Img
        fluid={store.relationships.field_store_flyer_image.localFile.childImageSharp.fluid}
        alt="Placeholder"
      />
    );

    // if (startDate && endDate) {
    //   flyerExpiration = `Valid ${flyerDate(startDate)} - ${flyerDate(endDate)}`;
    // }
  } catch (e) {}

  // console.log('flyer', {
  //   startDate,
  //   endDate,
  //   flyerExpiration,
  //   formatted: flyerDate(startDate),
  // });

  return (
    <div className={styles.storeFlyerWeekSection}>
      <div className={styles.storeFlyerWeekContainer}>
        <div className={styles.storeFlyerWeekColumn}>
          <h3 className={styles.storeFlyerWeekSubtitle}>
            {String(store.flyerSubtitle || "Longo's [month] Flyer").replace(
              '[month]',
              moment().format('MMMM')
            )}
          </h3>
          <h2 className={styles.storeFlyerWeekTitle}>{store.field_store_flyer_title}</h2>
          <p className={styles.storeFlyerWeekSummary}>{store.field_store_flyer_body}</p>
          {/*{!!flyerExpiration && <p className={styles.storeFlyerWeekCaption}>{flyerExpiration}</p>}*/}
          <a
            className={styles.storeFlyerWeekLink}
            href={app ? `/app${store.field_store_flyer_link.uri.replace('internal:', '')}` : store.field_store_flyer_link.uri.replace('internal:', '')}
          >
            View Flyer
          </a>
        </div>
        <div className={styles.storeFlyerWeekColumn}>
        <a href={app ? `/app${store.field_store_flyer_link.uri.replace('internal:', '')}` : store.field_store_flyer_link.uri.replace('internal:', '')}>
            {flyerImage}
          </a>
        </div>
      </div>
    </div>
  );
};

StoreFlyerWeek.propTypes = {
  store: PropTypes.object.isRequired,
};

export default StoreFlyerWeek;
