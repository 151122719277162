// Libs
import React from 'react';
import * as PropTypes from 'prop-types';
import { isNull, isObject, get, has } from 'lodash';
// Deps
import Img from 'gatsby-image';
import SiteLink from '../common/site-link';
// Assets
import styles from './store-promoted-feature.module.scss';

const StorePromotedFeature = ({ department = {} }) => {
  const content = {
    coverImage: get(department, 'relationships.field_department_image.localFile.childImageSharp.fluid', null),
    bioPicture: get(department, 'relationships.field_department_portrait.localFile.childImageSharp.fluid', null),
    feature: {
      heading: get(department, 'field_department_feature_heading.value', null),
      subHeading: get(department, 'field_department_feature_sub.value', null),
      body: get(department, 'field_department_feature_body.processed', null),
      link: get(department, 'field_department_feature_link', { uri: '', title: '' }),
    },
  };

  const bodyRemoveP = content.feature.body.split('<p>').join('');
  const bodyFormatted = bodyRemoveP.split('</p>').join('');

  return (
    <div className={styles.storePromotedFeature}>
      <div className={styles.storePromotedFeatureLayout}>
        {isObject(content.coverImage) && <Img
          className={styles.storePromotedFeatureLayoutLeft}
          fluid={content.coverImage}
        />}
        <div className={styles.storePromotedFeatureLayoutRight}>
          {isObject(content.bioPicture) && <Img
            alt="Bio Profile Pic"
            className={styles.storePromotedFeatureImageBio}
            fluid={content.bioPicture}
          />}
          {!isNull(content.feature.heading) && <h3 className={styles.storePromotedFeatureTitle}>
            {content.feature.heading}
          </h3>}
          {!isNull(content.feature.subHeading) && <span className={styles.storePromotedFeaturePosition}>
            {content.feature.subHeading}
          </span>}

          {!isNull(bodyFormatted) && <blockquote className={styles.storePromotedFeatureCopy} dangerouslySetInnerHTML={{ __html: bodyFormatted }}></blockquote>}
          
          {has(content.feature.link, 'uri') &&
          content.feature.link.uri !== '' &&
          <SiteLink
            className={styles.storePromotedFeatureLink}
            href={department.field_department_feature_link.uri}
          >
            {department.field_department_feature_link.title}
          </SiteLink>}
        </div>
      </div>
    </div>
  );
};

StorePromotedFeature.propTypes = {
  department: PropTypes.shape({
    relationships: PropTypes.shape({
      field_department_image: PropTypes.shape({
        localFile: PropTypes.shape({
          childImageSharp: PropTypes.object.isRequired,
        }).isRequired,
      }).isRequired,
      field_department_portrait: PropTypes.shape({
        localFile: PropTypes.shape({
          childImageSharp: PropTypes.object,
        }),
      }),
    }).isRequired,
    field_department_feature_heading: PropTypes.shape({
      value: PropTypes.string.isRequired,
    }),
    field_department_feature_body: PropTypes.shape({
      value: PropTypes.string.isRequired,
    }),
    field_department_feature_link: PropTypes.shape({
      uri: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
    }),
    field_department_feature_sub: PropTypes.shape({
      value: PropTypes.string.isRequired,
    }),
  }).isRequired,
};

export default StorePromotedFeature;
