// Libs
import React, { useState } from 'react';
import * as PropTypes from 'prop-types';
// Assets
import styles from './store-whats-in.module.scss';
import Img from 'gatsby-image';
// Custom Libs.
import SiteLink from "../common/site-link";
import StoreWhatsInContent from '../common/store/store-what-in-content';

const StoreWhatsIn = ({ store }) => {
  return (
    <div className={styles.storeWhatsIn}>
      <h2 className={styles.storeWhatsInTitle}>What's in store</h2>
      <div className={styles.storeWhatsInIntro}>
        {store.relationships.field_store_features.map((item, index) => {
          const indexKey = `field_store_features_${index}`;
          return (
            <div className={styles.storeWhatsInGridItem} key={indexKey}>
              <Img
                className={styles.storeWhatsInItemImage}
                fluid={item.relationships.field_sf_image.localFile.childImageSharp.fluid}
                alt="Placeholder"
              />
              <h3 className={styles.storeWhatsInSubtitle}>{item.title}</h3>
              {item.body && <div className={styles.storeWhatsInItemSummary} dangerouslySetInnerHTML={{__html: item.body.processed}}/>}
              {item.field_sf_link.map((item, key) => {
                return (
                  <div key={key}>
                    <SiteLink key={key} href={item.uri}>
                      {item.title}
                    </SiteLink>
                  </div>
                );
              })}
            </div>
          );
        })}
      </div>
      <StoreWhatsInContent store={store} />
    </div>
  );
};

StoreWhatsIn.propTypes = {
  store: PropTypes.object.isRequired,
};

export default StoreWhatsIn;
