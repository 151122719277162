// Libs.
import { useState, useEffect } from 'react';
// Custom Libs.
import { loadGoogleMapsLib } from './../libs/external-lib-loader';

export default function useGoogleMapsLoader() {
  const [libLoaded, setLibLoaded] = useState(false);

  useEffect(() => {
    if (!libLoaded) {
      loadGoogleMapsLib()
        .then((api) => {
          setLibLoaded(true);
        })
        .catch((e) => {
          console.log('Google APIs load error:', e);
        });
    }
  }, []);

  return libLoaded;
}
