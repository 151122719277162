// Libs
import React from 'react';
import { isEmpty } from 'lodash';
// Deps
import Layout from '../../components/layout/layout';
import StoreDetails from '../../components/store/store-details';
import StoreFlyerWeek from '../../components/store/store-flyer-week';
// import StoreCookingClass from '../../components/store/store-cooking-class';
import SEO from '../../components/layout/seo';

const StorePage = ({ pageContext }) => {
  const { store } = pageContext;
  return (
    <Layout>
      <SEO title={store.title} />
      <StoreDetails store={store} />

      {// Hidden for Go Live temporarily.
        /*<StoreCookingClass store={store} />*/
      }

      {!isEmpty(store.field_store_flyer_link) ? <StoreFlyerWeek store={store} /> : ''}
    </Layout>
  );
};

export default StorePage;
