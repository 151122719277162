// Libs
import React, { useEffect, useState } from 'react';
import * as PropTypes from 'prop-types';
import { get, isEmpty, uniqueId } from 'lodash';
import moment from 'moment';
// Assets
import styles from './store-holiday-hours.module.scss';
import { transformTo12 } from '../../../libs/timeTransform';
import { divmod } from '../../../libs/math';
import locale from '../../../locale/locale';

const StoreHolidayHours = ({store}) => {
  const [holidays, setHolidays] = useState();

  useEffect(() => {
    const holidayList = get(store, 'relationships.field_holidays', []);
    setHolidays(holidayList);
    // console.log('holiday store', holidayList, store);
  }, [store]);

  return (
    <>
      {!isEmpty(holidays) && (
        <div className={styles.storeDetailsHolidayHours}>
          {holidays.map((holiday, index) => {
            const key = uniqueId(`holiday_hours_${index}`);
            const holidayDate = get(holiday, 'date', null);
            if (holidayDate && moment(holidayDate, 'YYYY-MM-DD').isSameOrAfter(moment(), 'day')) {
              const openTime = get(holiday, 'time.from', 0);
              const closingTime = get(holiday, 'time.to', 0);

              let openingtime = transformTo12(...divmod(openTime / 36, 100));
              let otime = openingtime.split(':');
              //console.log(abc);
              //let otime = openingtime.split('');
              //console.log(otime);
              let openingHour = otime[0];
              let StoreOpenTime = openingtime.indexOf('00') > -1 ? (openingHour+ ":00am") :(openingHour+ ":30am");

              let closeing =transformTo12(...divmod(closingTime / 36, 100));
              let cTime = closeing.split(':');
              let closingHour = cTime[0];
              let StoreCloseTime = closeing.indexOf('00') > -1 ? (closingHour+ ":00pm") :(closingHour+ ":30pm");

              return (
                <div key={key} className={styles.storeDetailsHolidayHoursColumn}>
                  <div>{holiday.name}</div>
                  {openTime !== closingTime ? (
                    <div>
                        {StoreOpenTime}
                      &mdash; {StoreCloseTime}
                    </div>
                  ) : (
                    <div>{locale.store.closed}</div>
                  )}
                </div>
              );
            } else {
              return <div key={key}/>;
            }
          })}
        </div>
      )}
    </>
  );
};

StoreHolidayHours.propTypes = {
  store: PropTypes.any.isRequired,
};

export default StoreHolidayHours;
