// Libs
import React, {useContext, useState} from 'react';
import * as PropTypes from 'prop-types';
// Assets
import styles from './store-what-in-content.module.scss';
import SiteLink from '../site-link';
import { redirectKeyPressHandler } from '../../../libs/accessibility';
import {AppContext} from "../../layout/AppLayout";

const StoreWhatsInContent = ({ store }) => {

  const [foodDrinksListExpanded, setFoodDrinksListExpanded] = useState(false);
  const [servicesListExpanded, setServicesListExpanded] = useState(false);
  const isApp = useContext(AppContext);

  const maxListSize = Math.max(
    store.relationships.field_store_services.length,
    store.relationships.field_store_food_and_drink.length
  );
  const maxColumnListSize = Math.ceil(maxListSize / 2);
  const servicesCol1 = store.relationships.field_store_services.slice(0, maxColumnListSize);
  const servicesCol2 = store.relationships.field_store_services.slice(
    maxColumnListSize,
    store.relationships.field_store_services.length
  );
  const foodDrinkCol1 = store.relationships.field_store_food_and_drink.slice(0, maxColumnListSize);
  const foodDrinkCol2 = store.relationships.field_store_food_and_drink.slice(
    maxColumnListSize,
    store.relationships.field_store_food_and_drink.length
  );

  const handleServicesExpand = (e) => {
    setServicesListExpanded(!servicesListExpanded);
  };

  const handleFoodDrinksExpand = (e) => {
    setFoodDrinksListExpanded(!foodDrinksListExpanded);
  };

  return (
    <div className={styles.storeWhatsInContent}>
      <div className={styles.storeWhatsInGridItem}>
        <h3
          role="button"
          tabIndex="0"
          className={styles.storeWhatsInSubtitle}
          onClick={handleFoodDrinksExpand}
          data-visibility={foodDrinksListExpanded ? 'visible' : 'hidden'}
        >
          Food & Drink
        </h3>
        <div
          className={
            foodDrinksListExpanded
              ? styles.storeWhatsInChevronExpanded
              : styles.storeWhatsInChevronCollapsed
          }
        ></div>
        <div
          className={
            styles.storeWhatsInItemListContainer +
            ' ' +
            (foodDrinksListExpanded
              ? styles.storeWhatsInItemListContainerExpanded
              : styles.storeWhatsInItemListContainerCollapsed)
          }
        >
          <div className={styles.storeWhatsInItemListColContainer}>
            <ul className={styles.storeWhatsInItemList} data-app={isApp}>
              {foodDrinkCol1.map((item, index) => {
                if (item.linkUrl !== undefined) {
                  return (
                    <li key={`foodAndDrinksCol1${index}`}>
                      <SiteLink href={item.linkUrl}>{item.name}</SiteLink>
                    </li>
                  );
                } else {
                  return <li key={`foodAndDrinksCol1${index}`}>{item.name}</li>;
                }
              })}
            </ul>
          </div>
          <div className={styles.storeWhatsInItemListColContainer}>
            <ul className={styles.storeWhatsInItemList} data-app={isApp}>
              {foodDrinkCol2.map((item, index) => {
                if (item.linkUrl !== undefined) {
                  return (
                    <li key={`foodAndDrinksCol2${index}`}>
                      <SiteLink href={item.linkUrl}>{item.name}</SiteLink>
                    </li>
                  );
                } else {
                  return <li key={`foodAndDrinksCol2${index}`}>{item.name}</li>;
                }
              })}
            </ul>
          </div>
        </div>
      </div>
      <div className={styles.storeWhatsInGridItem}>
        <h3
          role="button"
          tabIndex="0"
          className={styles.storeWhatsInSubtitle}
          data-visibility={servicesListExpanded ? 'visible' : 'hidden'}
          onClick={handleServicesExpand}
          onKeyPress={redirectKeyPressHandler(handleServicesExpand)}
        >
          Services
        </h3>
        <div
          className={
            servicesListExpanded
              ? styles.storeWhatsInChevronExpanded
              : styles.storeWhatsInChevronCollapsed
          }
        ></div>
        <div
          className={
            styles.storeWhatsInItemListContainer +
            ' ' +
            (servicesListExpanded
              ? styles.storeWhatsInItemListContainerExpanded
              : styles.storeWhatsInItemListContainerCollapsed)
          }
        >
          <div className={styles.storeWhatsInItemListColContainer}>
            <ul className={styles.storeWhatsInItemList} data-app={isApp}>
              {servicesCol1.map((item, index) => {
                if (item.linkUrl !== undefined) {
                  return (
                    <li key={`servicesCol1${index}`}>
                      <SiteLink href={item.linkUrl}>{item.name}</SiteLink>
                    </li>
                  );
                } else {
                  return <li key={`servicesCol1${index}`}>{item.name}</li>;
                }
              })}
            </ul>
          </div>
          <div className={styles.storeWhatsInItemListColContainer}>
            <ul className={styles.storeWhatsInItemList} data-app={isApp}>
              {servicesCol2.map((item, index) => {
                if (item.linkUrl !== undefined) {
                  return (
                    <li key={`servicesCol2${index}`}>
                      <SiteLink href={item.linkUrl}>{item.name}</SiteLink>
                    </li>
                  );
                } else {
                  return <li key={`servicesCol2${index}`}>{item.name}</li>;
                }
              })}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

StoreWhatsInContent.propTypes = {
  store: PropTypes.object.isRequired,
};

export default StoreWhatsInContent;
