// Libs.
import React from 'react';
import * as PropTypes from 'prop-types';
// Assets.
import styles from './store-set-action.module.scss';
import { useDefaultStore } from '../../hooks/useDefaultStore';
// Custom Libs.
import { redirectKeyPressHandler } from '../../libs/accessibility';
import navigate from '../../libs/navigate';

const StoreSetAction = ({ saveLabel, changeLabel, storeId }) => {
  const defaultStore = useDefaultStore();
  const currentIsDefault = String(defaultStore.storeId) === String(storeId);
  const actionLabel = currentIsDefault ? changeLabel : saveLabel;
  const handleAction = (e) => {
    e.stopPropagation();
    if (currentIsDefault && typeof window !== 'undefined') {
      navigate('/find-store');
    } else {
      defaultStore.setStore(storeId);
    }
  };

  return (
    <>
    {!currentIsDefault && <span
      role="button"
      tabIndex="0"
      onClick={handleAction}
      onKeyPress={redirectKeyPressHandler(handleAction)}
      className={styles.storeSetAction}
    >
      {actionLabel}
    </span>}
    </>
  );
};

StoreSetAction.defaultProps = {
  saveLabel: 'Set as my store',
  changeLabel: 'Change my store',
};

StoreSetAction.propTypes = {
  storeId: PropTypes.number.isRequired,
  saveLabel: PropTypes.string.isRequired,
  changeLabel: PropTypes.string.isRequired,
};

export default StoreSetAction;
