// Libs
import React, { useState, useEffect } from 'react';
import { Map, Marker } from 'google-maps-react';
// Assets,
import selectedMarker from '../../../images/icons/map-pin-selected.svg';
import unselectedMarker from '../../../images/icons/map-pin-unselected.svg';
import mapStyles from '../../../data/mapStyle.json';

const StoreLocationMap = ({ google, focusPoint, otherPoints, zoom }) => {
  // Verify otherPoints is a viable array.
  otherPoints = Array.isArray(otherPoints) ? otherPoints : [];

  // Set zoom level
  zoom = zoom || 12;

  const [resizeEventIsBound, bindResizeEvent] = useState(false);
  const [containerStyle, setContainerStyle] = useState({
    width: '100%',
    height: '484px',
    position: 'relative',
  });
  const resizeHandler = () => {
    if (window.innerWidth >= 990) {
      setContainerStyle({
        width: '100%',
        height: '484px',
        position: 'relative',
      });
    } else if (window.innerWidth < 990 && window.innerWidth >= 768) {
      setContainerStyle({
        width: '100%',
        height: '535px',
        position: 'relative',
      });
    } else if (window.innerWidth < 768) {
      setContainerStyle({
        width: '100%',
        height: window.innerWidth * 0.68 + 'px',
        position: 'relative',
      });
    }
  };

  const unbindResize = () => {
    bindResizeEvent(false);
    window.onresize = null;
  };
  useEffect(() => {
    if (!resizeEventIsBound) {
      window.onresize = resizeHandler;
      bindResizeEvent(true);
    }
    return unbindResize;
  }, []);
  return (
    <>
      <Map
        google={google}
        zoom={zoom}
        styles={mapStyles}
        containerStyle={containerStyle}
        initialCenter={focusPoint}
        center={focusPoint}
        disableDefaultUI={true}
      >
        {/*<Marker
          name={''}
          position={focusPoint}
          icon={otherPoints.length > 0 ? selectedMarker : unselectedMarker}
        />*/}
        <Marker
          name={''}
          position={focusPoint}
          icon={selectedMarker}
        />
        {otherPoints.map((point, index) => (
          <Marker key={`mapPointer_${index}`} name={''} position={point} icon={unselectedMarker} />
        ))}
      </Map>
    </>
  );
};

export default StoreLocationMap;
