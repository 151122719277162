// Libs
import React from 'react';
import * as PropTypes from 'prop-types';
// Assets
import styles from './store-flyer-link.module.scss';
import SiteLink from '../common/site-link';

const StoreFlyerLink = ({ link }) => {
  const { uri, title } = link || {};

  if (!uri) {
    return null;
  }

  return (
    <SiteLink className={styles.storeFlyerLink} href={uri}>
      {title}
    </SiteLink>
  );
};

StoreFlyerLink.defaultProps = {
  link: null,
};

StoreFlyerLink.propTypes = {
  link: PropTypes.shape({
    uri: PropTypes.string,
    title: PropTypes.string,
  }),
};

export default StoreFlyerLink;
